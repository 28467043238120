@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@include media-breakpoint-down(md) {
    .image-small {
        height: 60px;
    }
}

.skip-step {
    text-decoration: line-through !important;
}
