@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// variable overrides
$input-placeholder-color: $gray-500;

@import "~bootstrap/scss/bootstrap";

body {
    background-color: $light;
}

.no-arrow::after {
    display: none;
}

a {
    text-decoration: none;
}

// fix for red invalid icons covering select down arrow
select.form-control.is-invalid {
    background-position: right calc(0.375em + 1.1875rem) center;
}

.card-list-margin-y {
    > div {
        margin-bottom: $card-spacer-y;
    }
}

.card-grid-equal-height {
    & .row {
        margin-bottom: 0.25rem;
    }
    & .card {
        height: 100%;
    }
    & .card-body {
        padding: 1rem;
    }
}

.nav-tabs .nav-link {
    text-transform: capitalize;
}

.server-tabs {
    .nav-item {
        .nav-link {
            color: #495057;
            border: none;
        }
        .nav-link.active {
            border: none;
            background-color: $gray-100;
            border-bottom: 2px solid $gray-600;
        }
        .nav-link:hover {
            background-color: $gray-300;
        }
    }
}
