@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.heading-sort {
    tr th {
        padding: 0;
    }
    button {
        min-height: 50px;
        width: 100%;
        color: $gray-900;
        text-align: left;
        font-weight: bold;
        border-radius: 0;
        text-decoration: none;
    }
    button:focus {
        text-decoration: none;
        box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    button:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
}

.truncate-table-text {
    table {
        table-layout: fixed;
        td:not(:last-child) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
